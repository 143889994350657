<template>
  <TopBar />
  <div class="error-container">
    <img src="/images/Error page 2.jpg" class="error-page-img" />
    <div class="error-content">
      <header class="error-header">
        <h1 class="error-message"><span class="error-message-big-text">Congratulations, </span>you have found <span
            class="error-message-big-text"><img src="/images/TopBarLogo.png" class="error-logo-img" /></span> hidden
          spot</h1>
        <h1 class="error-message">Let us help you go back to our home page.</h1>
      </header>
      <div class="bth-button-container">
        <a class="btn btn-primary" @click="goHome">This way please <svg xmlns="http://www.w3.org/2000/svg" width="34"
            height="34">
            <path d="M11.293 4.707 17.586 11H4v2h13.586l-6.293 6.293 1.414 1.414L21.414 12l-8.707-8.707-1.414 1.414z"
              fill="#e40404" />
          </svg></a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const props = defineProps({
  error: {
    type: Object,
    required: true
  }
});

const router = useRouter();

const goHome = () => router.push('/');
</script>

<style lang="scss" scoped>
:root {
  --color-primary: #0d395b;
  --color-white: #fff;
  --color-red: #e40404;
  --color-red-darken: #aa0505;
  --color-text: #55636b;
  --box-shadow:
    0px 0px 2px 1px #bebebe,
    0px 6px 6px -4px white,
    0px 0px 2px 2px white;
  --box-shadow-hover:
    0px 0px 1px 6px #d4d4d4,
    0px 4px 4px -14px white,
    0px 0px 1px 1px white;
  --font-main: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --shadow-default: 2px 2px 6px rgba(14, 54, 93, 0.2);
  --border-default: 1px solid rgba(14, 54, 93, 0.2);
  $bottom-gap: 50px;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; // Changed from center to flex-start
  min-height: 100dvh;
  font-family: var(--font-main);
  position: relative;
  width: 100%;
  overflow: hidden;

  .error-page-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 50px;

    background: {
      image: url("/images/Error-page-2.jpg");
      size: cover;
      position: center;
      repeat: no-repeat;
    }

    z-index: 0;
  }

  .error-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    width: 100%;

    .error-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;
      white-space: nowrap;
      text-align: center;
      line-height: 0px;

      .error-message {
        font-size: clamp(16px, 3vw, 24px);
        color: #0e365d;
        margin-top: 0.5rem;
        font-weight: 500;
        line-height: -2rem;

        .error-message-big-text {
          letter-spacing: 3.5px;
          font-size: clamp(18px, 3vw, 34px);
          font-weight: 500;
        }

        .error-logo-img {
          width: 7%;
          height: auto;
          vertical-align: middle;
          margin-bottom: 20px;
        }
      }
    }

    & .bth-button-container {
      display: flex;
      flex-direction: row;
      align-content: center;
      margin-left: 32vw;
      white-space: nowrap;

      & .btn {
        font-size: 1.5rem;
        font-weight: 700;
        cursor: pointer;
        transition: all 0.2s ease-in;
        color: var(--color-red);
        text-decoration: underline;

        & svg {
          width: 24px;
          height: 24px;
          fill: var(--color-white);
          vertical-align: middle;
          text-decoration: underline;
          stroke: #e40404;
          stroke-width: 2px;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>

import revive_payload_client_F37S4V0K92 from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_wy6lqjf7uk6ty6h7c42wrfqgsq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3fjwpjWOKo from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_wy6lqjf7uk6ty6h7c42wrfqgsq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_57knNgBxqc from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_wy6lqjf7uk6ty6h7c42wrfqgsq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_WOUW8hhLrI from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_wy6lqjf7uk6ty6h7c42wrfqgsq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_47o65x9Iad from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_wy6lqjf7uk6ty6h7c42wrfqgsq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_tEqpR2t1b5 from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_wy6lqjf7uk6ty6h7c42wrfqgsq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FwdxgdP21X from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_wy6lqjf7uk6ty6h7c42wrfqgsq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/dev/oxia-website/.nuxt/components.plugin.mjs";
import prefetch_client_oC27tDpfyK from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_wy6lqjf7uk6ty6h7c42wrfqgsq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_F37S4V0K92,
  unhead_3fjwpjWOKo,
  router_57knNgBxqc,
  payload_client_WOUW8hhLrI,
  navigation_repaint_client_47o65x9Iad,
  check_outdated_build_client_tEqpR2t1b5,
  chunk_reload_client_FwdxgdP21X,
  components_plugin_KR1HBZs4kY,
  prefetch_client_oC27tDpfyK
]